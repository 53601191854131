import { useNavigate } from "react-router-dom";
import presentImage from "../../Assets/Images/present_image.png";
import "./Rules.scss";
import logo from "../../Assets/Images/ЛОГОТИП.png";
import bot from "../../Assets/Images/bot.png";
import { useResize } from "../../Hooks/useResize";

const Rules = () => {
  const navigate = useNavigate();
  const size = useResize();

  return (
    <div className="WrapperRules">
      <div className="RulesHeader">
        <div className="headerDescription">
          <h2>В рамках исследования просим пройти опрос. Примерное время прохождения 10 мин.</h2>
          
        </div>

        {/* <div className="botBox">
          <div onClick={() => window.open("https://telegram.me/boxtestQA_bot")}>
            Задать вопрос
          </div>
          <img
            className="botLogo"
            alt=""
            src={bot}
            onClick={() => window.open("https://telegram.me/boxtestQA_bot")}
          ></img>
          <img alt="" src={logo}></img>
        </div> */}
      </div>
      <div className="RulesBox">
        <h2>Соберите сами свою жизнь!</h2>
        <p>
          В ближайший Новый год, с последним ударом курантов вся наша жизнь
          изменится.
        </p>
        <p>
          Самым сказочным образом машина времени перенесёт нас всех в новые
          времена.
        </p>
        <p>
          И Вы понимаете, что этого никак не избежать; но на это можно прямо
          повлиять.
        </p>
        <p>
          Каким именно будет наш общий новый жизненный уклад – зависит только от
          Вас.
        </p>
        <p>
          У Вас есть ровно 100 единиц универсального ресурса; ни больше, но и не
          меньше.
        </p>
        <p>
          И есть 55 образов жизни, каждый из которых отображается каким-либо
          фильмом.
        </p>
        <p>
          Соберите свой собственный набор условий, харизмы, энергетики, и
          обстоятельств.
        </p>
        <p>
          Сконструируйте тот уклад, в котором Вам лучше бы всего жить со своими
          близкими.
        </p>
        <p>
          Создайте свою реальность – распределите ресурс по всем произведениям
          из списка.{" "}
        </p>
        <p>
          Определите свой максимальный комфорт, смешивая всё возможное и
          допустимое.
        </p>
        <p>
          Вы можете и не распределить сколько-то ресурса; и можно даже весь его
          не трогать.
        </p>
        <p>
          Но чем больше ресурса останется у Вас – тем больше общую среду
          определят другие.
        </p>

        <h2>Пусть мир вокруг будет таким, как Вы захотите!</h2>
      </div>{" "}
      <div className="wrapperBottomBox">
        <button
          className="btnActive btnActiveBig"
          onClick={() => navigate("/Registration")}
        >
          Начать
        </button>
        {/* <div className="mapBox">
          <div className="stepBox">
            <div>шаг</div>
            <div className="currentStep">1</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="currentStep">2</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="stepNumber">3</div>
          </div>
          <div className="stepLine"></div>
          <div className="stepBox">
            <div>шаг</div>
            <div className="stepNumber">4</div>
          </div>
          <div className="stepLine"></div>
          <div>
            <img src={presentImage} alt=""></img>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Rules;
