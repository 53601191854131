import React from "react";
import { Route, Routes, HashRouter } from "react-router-dom";

import TokenData from "./Pages/TokenData/TokenData";
import Registration from "./Pages/Registration/Registration";
import Rules from "./Pages/Rules/Rules";
import Result from "./Pages/Result/Result";
import PrizeDraw from "./Pages/PrizeDraw/PrizeDraw";

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/Registration" element={<Registration />} />
        <Route exact path="/TokenData" element={<TokenData />} />
        <Route exact path="/" element={<Rules />} />
        <Route exact path="/Result" element={<Result />} />
        <Route exact path="/PrizeDraw" element={<PrizeDraw />} />
      </Routes>
    </HashRouter>
  );
};

export default App;
