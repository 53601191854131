export class Send {
  static get_api = "https://n2025.ru:4443/get_data_to_fill";
  static send_api = "https://n2025.ru:4443/save_data";

  static async getAll() {
    const response = await fetch(this.get_api);

    const result = await response.json();
    return result;
  }

  static async sendAll(data) {
    const response = await fetch(this.send_api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    return result;
  }
}

export default Send;
